import { createApp } from 'vue';
import { createStore } from 'vuex';
import moment from 'moment';
import App from './App.vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap';

const store = createStore({
  state() {
    return {
      waitTime: 0,
      waitlist: [],
      summoned: [],
    };
  },
  mutations: {
    initialiseStore(state) {
      // Check if the ID exists
      if (localStorage.getItem('store')) {
        // Replace the state object with the stored item
        this.replaceState(Object.assign(state, JSON.parse(localStorage.getItem('store'))));
      }
    },
    increment(state) {
      state.count += 1;
    },
    saveToList(state, obj) {
      state.waitlist.push(obj);
    },
  },
  getters: {
    waitlist(state) {
      return state.waitlist;
    },
  },
});

store.subscribe((mutation, state) => {
  // Store the state object as a JSON string
  localStorage.setItem('store', JSON.stringify(state));
});

const app = createApp(App);

app.config.globalProperties.$moment = moment;

app.use(store, moment).mount('#app');
