<template>
  <div class="tables">Here's where all the tables will show up</div>
</template>

<script>
export default {
  name: 'Tables',
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tables {
  background-color: #efefef;
  width: 50%;
  flex: 1 1 50%;
}
</style>
