<template>
  <svg
    class="icon"
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    v-bind:width="width"
    v-bind:height="height"
    v-html="svgContent()"
  ></svg>
</template>

<script>
import icons from './icons';

export default {
  name: 'Icon',
  props: ['name'],
  methods: {
    svgContent() {
      if (Object.keys(icons).indexOf(this.$props.name) >= 0) {
        return icons[this.$props.name];
      }
      return '';
    },
  },
};
</script>
