<template>
  <div class="modal" id="addPartyModal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Customer to Waitlist</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="mb-4">
            <label for="partyName" class="form-label">Customer Name</label>
            <input
              type="text"
              class="form-control"
              id="partyName"
              placeholder="Smith"
              v-model="customerName"
            />
          </div>
          <div v-if="!bigParty" class="mb-4">
            <label class="form-label">Party Size</label><br />
            <input
              type="radio"
              class="btn-check"
              v-model="partySize"
              id="party1"
              value="1"
              autocomplete="off"
            />
            <label class="btn btn-outline-primary" for="party1">1</label>
            <input
              type="radio"
              class="btn-check"
              v-model="partySize"
              id="party2"
              value="2"
              autocomplete="off"
            />
            <label class="btn btn-outline-primary" for="party2">2</label>
            <input
              type="radio"
              class="btn-check"
              v-model="partySize"
              id="party3"
              value="3"
              autocomplete="off"
            />
            <label class="btn btn-outline-primary" for="party3">3</label>
            <input
              type="radio"
              class="btn-check"
              v-model="partySize"
              id="party4"
              value="4"
              autocomplete="off"
            />
            <label class="btn btn-outline-primary" for="party4">4</label>
            <input
              type="radio"
              class="btn-check"
              v-model="partySize"
              id="party5"
              value="5"
              autocomplete="off"
            />
            <label class="btn btn-outline-primary" for="party5">5</label>
            <input
              type="radio"
              class="btn-check"
              v-model="partySize"
              id="party6"
              value="6"
              autocomplete="off"
            />
            <label class="btn btn-outline-primary" for="party6">6</label>
            <input
              type="radio"
              class="btn-check"
              v-model="partySize"
              id="party7"
              value="7"
              autocomplete="off"
            />
            <label class="btn btn-outline-primary" for="party7">7</label>
            <input
              type="radio"
              class="btn-check"
              v-model="partySize"
              id="party8"
              value="8"
              autocomplete="off"
            />
            <label class="btn btn-outline-primary" for="party8">8</label>

            <input type="radio" class="btn-check" name="options" id="bigger" autocomplete="off" />
            <label @click="biggerParty" class="btn btn-secondary" for="bigger">Bigger</label>
          </div>
          <div v-else class="mb-4">
            <label>Large Party Size</label>
            <input v-if="bigParty" class="form-control" type="number" placeholder="" />
          </div>
          <div class="mb-4">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                id="insode"
                value="inside"
                checked
                v-model="location"
              />
              <label class="form-check-label" for="insode"> Inside</label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                id="outside"
                value="outside"
                v-model="location"
              />
              <label class="form-check-label" for="outside"> Outside</label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                id="firstAvailable"
                value="firstAvailable"
                v-model="location"
              />
              <label class="form-check-label" for="firstAvailable">First Available </label>
            </div>
          </div>
          <div class="mb-4">
            <label for="phone" class="form-label">Phone Number</label>
            <input
              v-model="phone"
              type="tel"
              class="form-control"
              id="phone"
              placeholder="(817) 555-1212"
              @input="formatPhone"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button @click="saveToList" type="button" class="btn btn-primary">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddPartyDialog',
  props: ['componentName'],
  data() {
    return {
      currentWait: 0,
      bigParty: false,
      customerName: '',
      partySize: 0,
      location: 'inside',
      phone: '',
    };
  },
  methods: {
    biggerParty() {
      this.bigParty = true;
    },
    formatPhone() {
      const x = this.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      const final = x[3] ? `- ${x[3]}` : '';
      this.phone = !x[2] ? x[1] : `(${x[1]}) ${x[2]} ${final}`;
    },
    saveToList() {
      const phone = this.phone.replace(/\D/g, '');
      // need timestamp
      const obj = {
        name: this.customerName,
        size: this.biggerParty > 0 ? this.biggerParty : this.partySize,
        location: this.location,
        status: 'waiting',
        phone,
        timestamp: new Date(),
        estWait: parseInt(this.currentWait, 10) === 0 ? 5 : this.currentWait,
      };
      this.bigParty = false;
      this.$store.commit('saveToList', obj);
      // change component key
      const key = parseInt(this.$.vnode.key, 10) + 1;
      this.$parent.updateKey('AddPartyDialog', key);
      // close the modal
      document.querySelector('#addPartyModal .btn-close').click();
      // send SMS
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped></style>
