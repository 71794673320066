<template>
  <header>Logo and menu here</header>
  <section>
    <Tables />
    <Waitlist />
  </section>
</template>

<script>
import Tables from './components/Tables.vue';
import Waitlist from './components/Waitlist/Waitlist.vue';

export default {
  name: 'App',
  components: {
    Tables,
    Waitlist,
  },
  beforeMount() {
    this.$store.commit('initialiseStore');
  },
};
</script>

<style lang="scss">
$default: #212738;
$white: #fff;

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  *,
  * > * {
    box-sizing: border-box;
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  text-align: center;
  color: $default;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;

  .container {
    padding: 1rem;
  }

  > header {
    flex: 0 1 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $default;
    color: $white;
    max-height: 72px;
  }

  > section {
    flex: 1 1 100%;
    display: flex;
  }
}

.modal-body {
  text-align: left;
}
.modal-footer {
  justify-content: flex-start;
}
.btn.btn-outline-primary {
  margin-right: 0.5em;
}
</style>
