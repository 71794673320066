<template>
  <div class="waitlist">
    <div data-bs-toggle="modal" data-bs-target="#addPartyModal" class="btn btn-full">
      + Add to List
    </div>
    <div v-if="noWait" class="container">
      Waitlist is empty<br />Current estimated wait time is zero minutes
    </div>
    <div v-else class="list">
      <div class="list-heading">
        <span class="name">Name</span>
        <span>Party Size</span>
        <span>status</span>
        <span>Wait</span>
      </div>
      <div v-for="party in waitlistArray" v-bind:key="party.id" class="list-item">
        <span class="name"
          >{{ party.name }}
          <Icon
            v-if="party.location !== 'inside'"
            :name="party.location"
            width="1.2em"
            height="1.2em"
        /></span>
        <span>{{ party.size }}</span>
        <span class="status">
          <img v-if="party.status !== 'waiting'" src="{{this.icon(party.status)}}"
        /></span>
        <span class="time" @click="summonParty(party.id, party.phone)"
          >{{ this.waitElapsed(party.timestamp) }} / {{ party.estWait }}m</span
        >
      </div>
    </div>
  </div>
  <AddPartyDialog :key="componentKeys.AddPartyDialog" />
  <ActionDialog :key="componentKeys.ActionDialog" />
</template>

<script>
import axios from 'axios';
import Icon from '../Icon.vue';
import AddPartyDialog from './AddPartyDialog.vue';
import ActionDialog from './ActionDialog.vue';

export default {
  name: 'Waitlist',
  components: { Icon, AddPartyDialog, ActionDialog },
  data() {
    return {
      showModal: false,
      currentWait: 0,
      addingToList: false,
      componentKeys: {
        AddPartyDialog: 0,
        ActionDialog: 0,
      },
    };
  },
  computed: {
    noWait() {
      return this.$store.getters.currentWait === 0;
    },
    waitlistArray() {
      return this.$store.getters.waitlist;
    },
  },
  methods: {
    displayLocation(locationString) {
      const map = {
        inside: 'In',
        outside: 'Out',
        firstAvailable: 'FA',
      };

      return locationString !== 'inside' ? map[locationString] : '';
    },
    biggerParty() {
      this.bigParty = true;
    },
    formatPhone() {
      const x = this.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      const final = x[3] ? `- ${x[3]}` : '';
      this.phone = !x[2] ? x[1] : `(${x[1]}) ${x[2]} ${final}`;
    },
    saveToList() {
      const phone = this.phone.replace(/\D/g, '');
      // need timestamp
      const obj = {
        id: `${this.customerName}|${this.partySize}`,
        name: this.customerName,
        size: this.biggerParty > 0 ? this.biggerParty : this.partySize,
        location: this.location,
        status: 'waiting',
        phone,
        timestamp: new Date(),
        estWait: parseInt(this.currentWait, 10) === 0 ? 5 : this.currentWait,
      };
      this.bigParty = false;
      this.$store.commit('saveToList', obj);
      // close the modal
      document.querySelector('#addPartyModal .btn-close').click();
      // send SMS
    },
    waitElapsed(time) {
      const now = this.$moment();
      return `${this.$moment(now).diff(time, 'minutes')}m`;
    },
    updateKey(componentName, key) {
      this.componentKeys[componentName] = key;
    },
    summonParty(id, phone) {
      const recipient = '+18178199915' || phone;
      axios
        .post('https://waitlist.quadgrande.com/api/', {
          message: 'HEART CAFE ~ Your table is ready! Please check in now at the host stand.',
          recipient,
        })
        .then((response) => {
          //   this.info = response;
          console.log(response); // eslint-disable-line no-console
        })
        .catch((error) => {
          console.log(error); // eslint-disable-line no-console
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.waitlist {
  width: 50%;
  flex: 1 1 50%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .icon {
    filter: invert(66%) sepia(0%) saturate(771%) hue-rotate(51deg) brightness(75%) contrast(89%);
  }

  .btn-full {
    flex: 0 0 2em;
    display: flex;
    padding: 1rem 2rem;
    justify-content: center;
    align-items: center;
    background-color: #468249;
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
  }

  .list {
    flex: 0 0 2em;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    font-weight: 600;
    .list-item {
      flex: 1 1 2em;
      display: flex;
      justify-content: space-between;
      &:nth-of-type(even) {
        background-color: #efefef;
      }
      span {
        flex: 1 1 20%;
        font-size: 1.2rem;
        padding: 0.5em;

        &.time {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.9rem;
        }
      }
    }

    .list-heading {
      //   padding: 0 0.5rem;
      flex: 0 0 2em;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #ccc;
      font-size: 0.8rem;
      text-transform: uppercase;
      font-weight: 600;
      color: #667;
      span {
        flex: 1 1 20%;
        padding: 0 0.5em;
      }
    }
    .name {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
